import React from 'react';
import { AuthContext } from "./auth-context";

export function withAuthContext(Component) {
  return function AuthComponent(props) {
    return (
      <AuthContext.Consumer>
        { (contexts) => {
          return <Component {...props} {...contexts} />
        }}
      </AuthContext.Consumer>
    )
  }
}
