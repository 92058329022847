import { useState, useEffect } from 'react';

import Firebase from '../../firebase';

export default function usePageContent(pageId, cachedState) {
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!cachedState) {
      setContent(cachedState);
    }
  }, [cachedState]);

  useEffect(() => {
    if (!cachedState) {
      const firebase = new Firebase();
      setLoading(true)
      firebase.getPageContent(pageId)
        .then(setContent)
        .then(() => {
          setLoading(false)
        }).catch(setError)
    }
  }, [pageId]);

  return [
    content,
    loading,
    error
  ]
}
