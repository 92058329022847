import React from 'react';
import { AuthContext } from "./auth-context";
import useOGAuthState from "../../hooks/auth/useOGAuthState";

const AuthProvider = (props) => {
  const [
    initializing,
    user,
    loading,
    error,
    setUser,
    fbUser
  ] = useOGAuthState(props.firebase);

  return (
    <AuthContext.Provider value={{
      authContext: {
        initializing: initializing,
        fbUser: fbUser,
        ogUser: user,
        userLoading: loading,
        userError: error
      }
    }}>
      {props.children}
    </AuthContext.Provider>
  )
};

export default AuthProvider;
