import React, { useEffect } from 'react';
import {withRouter} from "react-router-dom";
import ReactGA from 'react-ga';

const OnshoreAnalytics = ({ location, children }) => {
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (<div className="analytics-block">{ children }</div>);
};

export default withRouter(OnshoreAnalytics);
