import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {withFirebase} from "../../firebase";
import {useAuthState} from "react-firebase-hooks/auth";


const PrivateRoute = ({firebase, component: Component, ...rest}) => {

  const [user, userLoading, userError] = useAuthState(firebase.auth);

  if (userLoading) return null;

  return (
    <Route {...rest} render={props => (
      !!user ?
        <Component {...props} />
        : <Redirect to="/" />
    )} />
  );
};

export default withFirebase(PrivateRoute);
