import React, {createContext, useContext, useReducer} from 'react';

export const PagesContentStateContext = createContext();

export const PagesContentStateProvider = ({reducer, initialState, children}) =>(
  <PagesContentStateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </PagesContentStateContext.Provider>
);

export const usePagesContentState = () => useContext(PagesContentStateContext);

export const pagesContentInitialState = {
  explorePage: {
    content: {},
    activities: [],
    filteredActivities: []
  },
  savedPage: {
    content: {},
    activities: [],
    filteredActivities: []
  }
};

export const pagesContentReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_EXPLORE_PAGE':
      return {
        ...state,
        explorePage: action.payload
      };

    case 'UPDATE_SAVED_PAGE':
      return {
        ...state,
        savedPage: action.payload
      };

    default:
      return state
  }
};
