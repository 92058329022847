import { useState, useEffect } from 'react';

import Firebase from '../../firebase';

export default function usePageBySlug(pageId, slugId, cachedState) {
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!cachedState) {
      setContent(cachedState);
    }
  }, [cachedState]);

  useEffect(() => {
    if (!cachedState) {
      const firebase = new Firebase();
      setLoading(true)
      firebase.getByField(pageId, 'slug', slugId)
        .then((result) => setContent(Object.values(result) ? Object.values(result)[0] : []))
        .then(() => {
          setLoading(false);
        }).catch(setError)
    }
  }, [pageId]);

  return [
    content,
    loading,
    error
  ]
}
