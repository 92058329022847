import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import flamelink from 'flamelink/app';
import 'flamelink/content';
import 'flamelink/storage';
import 'flamelink/navigation';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let firebaseApp = null;

class Firebase {
  constructor() {
    if (!app.apps.length)
      firebaseApp = app.initializeApp(firebaseConfig);

    this.auth = app.auth();

    this.firestore = app.firestore();

    this.storage = app.storage();

    this.flamelink = flamelink({
      firebaseApp, // required
      dbType: 'cf', // can be either 'rtdb' or 'cf' for Realtime DB or Cloud Firestore
      env: process.env.REACT_APP_FLAMELINK_ENV, // optional, default shown
      locale: process.env.REACT_APP_FLAMELINK_LOCALE, // optional, default shown
      precache: process.env.REACT_APP_FLAMELINK_PRECACHE // optional, default shown. Currently it only precaches "schemas" for better performance
    });

    this.googleProvider = app.auth.GoogleAuthProvider;
    this.facebookProvider = app.auth.FacebookAuthProvider;
    this.emailProvider = app.auth.EmailAuthProvider;
  }

  /* PAGE CONTENT */
  getPageContent = async (pageId) => {
    return await this.flamelink.content.get({
      schemaKey: pageId,
      populate: true
    })
  };

  /* SCHEMA CONTENT */

  getActivities = async () => {
    return await this.flamelink.content.get({
      schemaKey: 'activities',
      populate: true,
      orderBy: 'order'
    })
  };

  getSchemaContent = async (schemaId) => {
    return await this.flamelink.content.get({
      schemaKey: schemaId,
      populate: true,
      orderBy: 'order'
    })
  };

  getByField = async (schemaId, fieldName, fieldValue) => {
    return await this.flamelink.content.getByField({
      schemaKey: schemaId,
      field: fieldName,
      value: fieldValue,
      populate: true
    });
  };

  getPointsOfInterest = async () => {
    return await this.flamelink.content.get({
      schemaKey: 'pointsOfInterest',
      orderBy: 'order'
    })
  };

  getPointsOfInterestByType = async (type) => {
    return await this.flamelink.content.getByField({
      schemaKey: 'pointsOfInterest',
      field: 'type',
      value: type
    })
  };

  /* NAV ITEMS */

  getNavItems = async (menuId) => {
    return await this.flamelink.nav.get({
      navigationKey: menuId
    });
  }

  /* FORMS */
  getFormById = async (formId) => {
    const schemasRef = this.firestore.collection('fl_schemas');
    return await schemasRef.get()
      .then((querySnap) => {
        return querySnap.docs.filter((doc) => {
          return doc.data().id === formId
        })[0];
      });
  };

  /* AUTH */

  userSignupWithEmailAndPassword = async (userData) => {

    const createUserResult = await this.auth.createUserWithEmailAndPassword(userData.email, userData.password);

    this.sendVerificationEmail();

    delete userData.photoBlob;
    delete userData.password;

    userData.firebaseUid = createUserResult.user.uid;
    userData.savedActivities = [];
    userData.providerId = 'password';
    userData.isProfileComplete = true;

    return await this.firestore.collection('og_users').doc(createUserResult.user.uid).set(userData);
  };

  userSignupWithGoogle = async ({ additionalUserInfo, user }) => {
    if (!additionalUserInfo) return Promise.reject('Invalid request');
    let userData = {};

    userData.photoUrl = additionalUserInfo.profile.picture || '';
    userData.name = additionalUserInfo.profile.name;
    userData.email = additionalUserInfo.profile.email;
    userData.firebaseUid = user.uid;
    userData.savedActivities = [];
    userData.interests = [];
    userData.providerId = additionalUserInfo.providerId;
    userData.recaptcha = true;
    userData.isProfileComplete = false;

    return await this.firestore.collection('og_users').doc(user.uid).set(userData);
  };

  userSignupWithFacebook = async ({ additionalUserInfo, user }) => {
    if (!additionalUserInfo) return Promise.reject('Invalid request');
    let userData = {};

    userData.photoUrl = additionalUserInfo.profile.picture.data.url;
    userData.name = additionalUserInfo.profile.name;
    userData.email = additionalUserInfo.profile.email;
    userData.firebaseUid = user.uid;
    userData.savedActivities = [];
    userData.interests = [];
    userData.providerId = additionalUserInfo.providerId;
    userData.recaptcha = true;
    userData.isProfileComplete = false;

    return await this.firestore.collection('og_users').doc(user.uid).set(userData);
  };

  saveUserActivity = async (activity, ogUser) => {
    return new Promise((resolve) => {
      this.firestore.collection('og_users').where("firebaseUid", "==", ogUser.firebaseUid)
        .get()
        .then((snap) => {
          snap.forEach(async doc => {
            let updateData = {
              savedActivities: [...ogUser.savedActivities, activity]
            };

            await this.firestore.collection('og_users').doc(doc.id).update({
              savedActivities: [...ogUser.savedActivities, activity]
            });

            resolve({...ogUser, ...updateData})
          })
        });
    });
  };

  removeSavedUserActivity = async (activity, ogUser) => {
    return new Promise((resolve) => {
      this.firestore.collection('og_users').where("firebaseUid", "==", ogUser.firebaseUid)
        .get()
        .then(snap => {
          snap.forEach(async (doc) => {
            if (!doc.exists) return;

            let savedActivities = [].concat(doc.data().savedActivities);
            let updateData;

            savedActivities = savedActivities.filter(act => {
              if (!!act) {
                return act.id !== activity.id
              } else {
                return false
              }
            });

            updateData = {savedActivities: [...savedActivities]};

            await this.firestore.collection('og_users').doc(doc.id).update(updateData);

            resolve({...doc.data(), ...updateData})
          })
        })
    });

  };

  updateUserAccount = async (userId, updateData) => {
    return await this.firestore.collection('og_users').doc(userId).update(updateData);
  };

  sendVerificationEmail = async () => {
    const user = this.auth.currentUser;

    return await user.sendEmailVerification();
  };

  reauthenticate = async (password) => {
    const user = this.auth.currentUser;
    const cred = this.emailProvider.credential(user.email, password);

    return await user.reauthenticateWithCredential(cred);
  };

  updatePassword = async (newPassword) => {
    console.log('new pass', newPassword);

    const user = this.auth.currentUser;

    return await user.updatePassword(newPassword);
  };
}

export default Firebase;
