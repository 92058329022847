import { useState, useEffect } from 'react';

import Firebase from '../../firebase';

export default function useNavItems(menuId) {
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const firebase = new Firebase();
    setLoading(true)
    firebase.getNavItems(menuId).then(setContent).then(() => setLoading(false)).catch(setError)
  }, [menuId]);

  return [
    content,
    loading,
    error
  ]
}
