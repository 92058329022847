import React from 'react';
import {Link, NavLink} from 'react-router-dom';

import './header.scss';

import Logo from '../../media/logo@2x.png';
import UserBadge from "../UserBadge/UserBadge";
import {withAuthContext} from "../../contexts/AuthContext/withAuthContext";
import {useAppState} from "../../AppState";


const Header = ({ isNavDrawerOpened, onOpenDrawer, authContext }) => {

  const [{}, dispatchAppState] = useAppState();

  return (
    <div className="header-nav">
      {/* desktop */}
      <div className="grid-middle header-nav--desktop">
        <div className="brand">
          <NavLink to="/" className="header-nav--logo">
            <img src={Logo} alt="OneShore" />
          </NavLink>
        </div>
        <nav>
          {/*<NavLink className="nav__link" to="/">Boat Maintenance</NavLink>*/}
          <NavLink exact className="nav__link" activeClassName="nav__link--active" to="/">Explore</NavLink>
          {
            !authContext.initializing && !authContext.userLoading ? (
              authContext.ogUser ? (
                <NavLink className="nav__link" activeClassName="nav__link--active" to="/my-route">My Route</NavLink>
              ) : null
            ) : null
          }
          <NavLink className="nav__link" activeClassName="nav__link--active" to="/support">Support</NavLink>
        </nav>
        {
          !authContext.initializing && !authContext.userLoading ? (
            authContext.ogUser ? (
              <UserBadge/>
            ) : (
              <button className="button button-secondary button-signin" onClick={() => dispatchAppState({ type: 'toggleLoginModal', payload: true })}>Sign-in</button>
            )
          ) : null
        }
      </div>

      {/* devices */}

      <div className="grid-center-middle-noBottom header-nav--devices">
        <div className="brand">
          <Link to="/" className="header-nav--logo">
            <img src={Logo} alt="OneShore" />
          </Link>
        </div>
        <nav>
          <button className="button button-burguer" data-action="openMenu" onClick={() => onOpenDrawer(!isNavDrawerOpened)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </nav>

        {/*<div className="col-right">*/}
        {/*  <button className="button button-quick-search">*/}
        {/*    <i className="icon icon-search"></i>*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    </div>
  )
};

export default withAuthContext(Header);
