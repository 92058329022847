import React, {createContext, useContext, useReducer} from 'react';

export const AppStateContext = createContext();

export const AppStateProvider = ({reducer, initialState, children}) =>(
  <AppStateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </AppStateContext.Provider>
);

export const BottomNavPositions = Object.freeze({
  ACTIVITIES: "activities",
  MAP: "map"
});

export const useAppState = () => useContext(AppStateContext);

export const appInitialState = {
  tagsModalOpened: false,
  navDrawerOpened: false,
  userDropdownOpened: false,
  loginModalOpened: false,
  bottomNavPosition: BottomNavPositions.MAP,
  globalWarning: null,
  savedActivitiesMode: false
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case 'toggleTagsModal':
      return {
        ...state,
        tagsModalOpened: action.payload
      };

    case 'toggleNavDrawer':
      return {
        ...state,
        navDrawerOpened: action.payload
      };

    case 'toggleUserDropdown':
      return {
        ...state,
        userDropdownOpened: action.payload
      };

    case 'toggleLoginModal':
      return {
        ...state,
        loginModalOpened: action.payload
      };

    case 'toggleForgotPasswordModal':
      return {
        ...state,
        forgotPasswordModalOpened: action.payload
      };

    case 'setBottomNavPosition':
      console.log('set nav pos', action.payload);

      return {
        ...state,
        bottomNavPosition: action.payload
      };

    case 'setGlobalWarning':
      return {
        ...state,
        globalWarning: action.payload
      };

    case 'resetGlobalWarning':
      return {
        ...state,
        globalWarning: null
      };

    case 'setSavedActivitiesMode':
      return {
        ...state,
        savedActivitiesMode: action.payload
      };

    default:
      return state
  }
};
