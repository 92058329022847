import React from 'react';
import './UserBadge.scss';
import {NavLink} from "react-router-dom";
import UserAvatar from '../../media/avatar.png';
import {withFirebase} from "../../firebase";
import {useAppState} from "../../AppState";

const UserBadge = ({ firebase }) => {

  const [{userDropdownOpened}, appStateDispatch] = useAppState();

  const handleSignOut = () => {
    return firebase.auth.signOut();
  };

  return (
    <div className="user-badge" onClick={() => appStateDispatch({ type: 'toggleUserDropdown', payload: !userDropdownOpened })}>
      <button className="user-badge__avatar">
        <img src={UserAvatar} alt=""/>
      </button>
      <ul className={`user-badge__dropdown ${userDropdownOpened ? 'is-opened' : ''}`}>
        {/*<li><NavLink to="/my-route">My Route</NavLink></li>*/}
        <li><NavLink to="/account">Account</NavLink></li>
        <hr/>
        <li onClick={handleSignOut}><a href="#">Sign out</a></li>
      </ul>
    </div>
  )
};

export default withFirebase(UserBadge);
