import React, { useState, useEffect } from 'react';
import {useAuthState} from "react-firebase-hooks/auth";

const useOGAuthState = (firebase) => {
  const [fbUser, initializing, fbUserError] = useAuthState(firebase.auth);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!!initializing) return;

    if (!fbUser) {
      setUser(null);
      return;
    }

    setLoading(true);

    const unlisten = firebase.firestore.collection('og_users').where('firebaseUid', '==', fbUser.uid).onSnapshot(
      (next) => {
        next.forEach((doc) => setUser(doc.data()));
        setLoading(false)
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => {
      unlisten()
    }
  }, [fbUser, initializing]);

  return [
    initializing,
    user,
    loading,
    error,
    setUser,
    fbUser
  ]

};

export default useOGAuthState;
