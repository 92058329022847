import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom"

import './App.scss';

import ComponentHeader from './components/Header/header';
import ComponentNavigation from './components/Navigation/SideNavigation'

import {toast, ToastContainer} from "react-toastify";
import {useAppState} from "./AppState";
import {pagesContentInitialState, pagesContentReducer, PagesContentStateProvider} from "./pages/PagesContentState";
import OnshoreAnalytics from "./components/Analytics/OnshoreAnalytics";
import PrivateRoute from "./components/Routes/PrivateRoute";
import CompleteProfilePage from "./pages/CompleteProfile/CompleteProfile";
import InterestsModal from "./components/InterestsModal/InterestsModal";
import {withAuthContext} from "./contexts/AuthContext/withAuthContext";
import {
  activityFiltersInitialState,
  ActivityFiltersProvider, activityFiltersReducer
} from "./contexts/ActivityFiltersContext/useActivityFilters";
import ErrorModal from "./components/ErrorModal/ErrorModal";
import {LoadScript} from "@react-google-maps/api";
import { compose } from 'recompose';
import {withFirebase} from "./firebase";
import Helmet from 'react-helmet';

const ExplorePage = React.lazy(() => import('./pages/Explore/explore'));
const ActivityDetailPage = React.lazy(() => import('./pages/ActivityDetail/ActivityDetail'));
const MyRoutePage = React.lazy(() => import('./pages/MyRoute/my-route'));
const SupportPage = React.lazy(() => import('./pages/Support/support'));
const SignupPage = React.lazy(() => import('./pages/Signup/Signup'));
const AccountPage = React.lazy(() => import('./pages/Account/Account'));
const SavedPage = React.lazy(() => import('./pages/Saved/saved'));
// const HomePage = React.lazy(() => import('./pages/Home/Home'));


const App = ({ authContext, firebase }) => {

  const [{tagsModalOpened, navDrawerOpened, userDropdownOpened, globalWarning}, appStateDispatch] = useAppState();

  const [interestsModalOpened, setInterestsModalOpened] = useState(false);

  const handleMainClick = (e) => {
    if (tagsModalOpened && e.target.tagName !== 'BUTTON') {
      appStateDispatch({ type: 'toggleTagsModal', payload: false })
    }

    if (navDrawerOpened) {
      appStateDispatch({ type: 'toggleNavDrawer', payload: false })
    }

    if (userDropdownOpened) {
      appStateDispatch({ type: 'toggleUserDropdown', payload: false })
    }
  };

  const resendVerificationEmail = async () => {
    console.log('resend verification email');

    try {
      await firebase.sendVerificationEmail()
    } catch (err) {
      console.error('resend verification email error', err)
    }

  };

  useEffect(() => {
    if (!!authContext.ogUser) {
      if (!authContext.ogUser.isProfileComplete) {
        if (!interestsModalOpened) setInterestsModalOpened(true)
      }
    }
  }, [authContext.ogUser]);

  useEffect(() => {
    if (!!authContext.fbUser) {
      if (!authContext.fbUser.emailVerified) {
        appStateDispatch({ type: 'setGlobalWarning', payload: <span>Please verify your email address. Didn't get an email? <a onClick={resendVerificationEmail}>Resend verification email</a></span>})
      }
    }
  }, [authContext.fbUser]);

  useEffect(() => {
    if (globalWarning) {
      toast.warn(globalWarning, {
        autoClose: false,
        containerId: 'GLOBAL',
        onClose: () => { appStateDispatch({ type: 'resetGlobalWarning' }) }
      })
    } else {

    }
  }, [globalWarning]);

  return (

      <BrowserRouter>
        <Helmet>
          <title>{process.env.REACT_APP_ENVIRONMENT === 'local' ? '(Local) Onshore Guide' : '(Alpha) Onshore Guide'}</title>
        </Helmet>
        <ActivityFiltersProvider initialState={activityFiltersInitialState} reducer={activityFiltersReducer}>
        <main className="main" onClick={handleMainClick}>
          <div className="container">
            <ToastContainer enableMultiContainer className="global-toast" toastClassName="global-toast__toast" bodyClassName="global-toast__body" containerId={'GLOBAL'} position={toast.POSITION.TOP_CENTER}/>
            <div className="grid">
              <ErrorModal>
                <OnshoreAnalytics>
                  <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  >
                    <PagesContentStateProvider initialState={pagesContentInitialState} reducer={pagesContentReducer}>
                        <Suspense fallback={<div></div>}>
                        <Switch>
                            <Route exact path='/' component={ExplorePage} />
                            <Route path='/signup' component={SignupPage} />
                            <Route path='/activity-detail/:slug' component={ActivityDetailPage} />
                            <Route path='/support' component={SupportPage} />
                            <PrivateRoute path='/account' component={AccountPage} />
                            <PrivateRoute path='/saved' component={SavedPage} />
                            <PrivateRoute path='/my-route' component={MyRoutePage} />
                            <PrivateRoute path='/complete-profile' component={CompleteProfilePage} />

                            <Route path='**' component={NotFound} />
                        </Switch>
                        </Suspense>

                      <ToastContainer enableMultiContainer/>
                      <InterestsModal
                        isModalOpen={interestsModalOpened}
                        user={authContext.ogUser}
                        triggerModalClose={() => setInterestsModalOpened(false)}
                      />
                    </PagesContentStateProvider>
                  </LoadScript>
                </OnshoreAnalytics>
              </ErrorModal>
            </div>
          </div>

          <ComponentHeader
            isNavDrawerOpened={navDrawerOpened}
            onOpenDrawer={(open) => appStateDispatch({ type: 'toggleNavDrawer', payload: open })}
            isUserDropdownOpened={userDropdownOpened}
            onUserDropdownOpen={(open) => appStateDispatch({ type: 'toggleUserDropdown', payload: open })}
          />
          <ComponentNavigation isDrawerOpened={navDrawerOpened}/>

        </main>
        </ActivityFiltersProvider>
      </BrowserRouter>
  )
}

const NotFound = () => {
  return (
    <h1>404.. This page is not found!</h1>
  )
}

export default compose(
  withAuthContext,
  withFirebase
)(App);
