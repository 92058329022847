import React from 'react';
import App from './App';

import Firebase, { FirebaseContext } from './firebase';
import AuthProvider from './contexts/AuthContext/auth-provider';
import {appInitialState, appReducer, AppStateProvider} from "./AppState";

import ReactGA from 'react-ga';

export default function Root() {

  const firebase = new Firebase();

  if (process.env.REACT_APP_ENVIRONMENT === "local") {
    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_ALPHA
      , {
        debug: false,
        titleCase: false,
        gaOptions: {
          siteSpeedSampleRate: 100
        }
      });
  } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_ALPHA
      , {
        debug: false,
        titleCase: false,
        gaOptions: {
          siteSpeedSampleRate: 100
        }
      });
  } else if (process.env.REACT_APP_ENVIRONMENT === "production") {
    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_BETA
      , {
        debug: false,
        titleCase: false,
        gaOptions: {
          siteSpeedSampleRate: 100
        }
      });
  }



  return (
    <AppStateProvider initialState={appInitialState} reducer={appReducer}>
      <FirebaseContext.Provider value={firebase}>
        <AuthProvider firebase={firebase}>
            <App/>
        </AuthProvider>
      </FirebaseContext.Provider>
    </AppStateProvider>
  );
}
