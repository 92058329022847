import React from 'react';

export const AuthContext = React.createContext(
  {
    authContext: {
      initializing: true,
      fbUser: null,
      ogUser: null,
      userLoading: false,
      userError: null
    }
  }
);
