import React, { Component } from 'react';
import ReactModal from 'react-modal'
import './ErrorModal.scss';
import Logo from "../../media/logo@2x.png";
import {NavLink} from "react-router-dom";

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error', error);
    console.log('Error Info', errorInfo);
  }

  triggerPageRefresh() {
    return window.location.reload(true);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ReactModal
          isOpen={this.state.hasError}
          shouldCloseOnOverlayClick={true}
          className="ErrorModal"
        >
          <header className="ReactModal--header ErrorModal--header">
            <img src={Logo} alt="logo" />
          </header>
          <div className="ReactModal--body ErrorModal--body">
            <p className="ReactModal--title LoginModal--title">
              Oops, looks like something went wrong on our end.
            </p>

            <div className="ReactModal--description ErrorModal--description">
              <p>We're terribly sorry, it looks like our developers are still at work trying to make the website work perfectly every time.</p>
              <p>If you want to help them identify the problem so that they can fix it quickly, they would very much appreciate <NavLink to="/support">your feedback.</NavLink></p>
              <p>However, you could also try to refresh the page and try again.</p>
            </div>

            <button className="button button-rounded button-social" onClick={this.triggerPageRefresh}>
              Refresh the Page
            </button>
          </div>
        </ReactModal>
      )
    }

    return this.props.children;
  }

}

export default ErrorModal;
