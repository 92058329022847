import React, { useState } from 'react';
import ReactModal from 'react-modal';

import './InterestsModal.scss';
import Logo from "../../media/logo@2x.png";
import {useSchemaContent} from "../../hooks/flamelink";
import InterestsList from "../InterestsList/InterestsList";
import Loading from "../Loading/Loading";
import {withFirebase} from "../../firebase";
import {Slide, toast} from "react-toastify";

const InterestsModal = ({
  isModalOpen,
  triggerModalClose,
  onInterestsSuccess,
  onInterestsError,
  user,
  firebase
                        }) => {

  const [interests, interestsLoading, interestsError] = useSchemaContent('activityCategories');
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [formError, setFormError] = useState(null);

  const handleInterestsSave = async () => {
    if (selectedInterests.length < 3) {
      setFormError('Please select at least 3 interests.')
    }

    let updateResult;

    try {
      updateResult = await firebase.updateUserAccount(user.firebaseUid, {
        interests: selectedInterests,
        isProfileComplete: true
      });
      console.log('update result', updateResult);
      toast.success(`Your interests were updated successfully.`, {
        autoClose: true,
        position: toast.POSITION.BOTTOM_CENTER,
        transition: Slide
      });
      triggerModalClose()
    } catch (err) {
      console.log('update err', err)
    }

  };

  return (
    <ReactModal
      isOpen={isModalOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={triggerModalClose}
      className="InterestsModal"
    >
      <header className="ReactModal--header InterestsModal--header">
        <img src={Logo} alt="logo" />
      </header>
      <div className="ReactModal--body InterestsModal--body">
        <p className="ReactModal--title InterestsModal--title">
          Please complete your profile:
        </p>
        <p className="ReactModal--subtitle InterestsModal--subtitle">
          Help us get to know you by choosing at least 3 interests below:
        </p>
        {
          formError ? (
            <div className={`form-status-message error`}>{formError}</div>
          ) : null
        }
        {
          !!interests ? (
            <InterestsList
              editable={true}
              interests={Object.values(interests)}
              onSelect={(selected) => setSelectedInterests(selected)}
            />
          ) : <Loading/>
        }
        <button className="ReactModal--action-btn InterestsModal--action-btn button button-round button-primary button-edit"
                onClick={handleInterestsSave}
        >Save</button>
      </div>
    </ReactModal>
  )
};

export default withFirebase(InterestsModal);
