import React, {createContext, useContext, useReducer} from 'react';

export const ActivityFiltersContext = createContext();

export const ActivityFiltersProvider = ({reducer, initialState, children}) =>(
  <ActivityFiltersContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </ActivityFiltersContext.Provider>
);

export const useActivityFilters = () => useContext(ActivityFiltersContext);

export const activityFiltersInitialState = {
  activeFilters: [],
  searchTerm: ''
};

export const activityFiltersReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ACTIVITY_FILTER':
      return {
        ...state,
        activeFilters: [...state.activeFilters, action.payload]
      };

    case 'REMOVE_ACTIVITY_FILTER':
      return {
        ...state,
        activeFilters: state.activeFilters.filter(activityFilter => activityFilter.id !== action.payload.id)
      };

    case 'RESET_ACTIVITY_FILTERS':
      return {
        ...state,
        activeFilters: activityFiltersInitialState.activeFilters
      };

    case 'MODIFY_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload
      };

    default:
      return state
  }
};
