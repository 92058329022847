import React from 'react';

import './CompleteProfile.scss';

const CompleteProfilePage = () => {
  return (
    <div className="complete-profile-page">
      
    </div>
  )
};

export default CompleteProfilePage;
