import React, { useState, useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'


import './SideNavigation.scss'
import TagsModal from '../TagsModal/TagsModal'

import { useSchemaContent, useNavItems } from "../../hooks/flamelink";
import { compose } from "recompose";
import { useAppState } from "../../AppState";
import { useAuthState } from 'react-firebase-hooks/auth';
import {withFirebase} from "../../firebase";
import {useActivityFilters} from "../../contexts/ActivityFiltersContext/useActivityFilters";

const MAX_TAGS_WHEN_COLLAPSED = 3;

const SIDENAV_ENABLED_ROUTES = [
  '/'
];

const PRIVATE_ROUTES = [
  '/account',
  '/my-route',
  '/saved'
];

function sortByOrder( a, b ) {
  if ( a.order < b.order ){
    return -1;
  }
  if ( a.order > b.order ){
    return 1;
  }
  return 0;
}

const SideNavigation = ({ firebase, location, isDrawerOpened }) => {

  const [user, userLoading, userError] = useAuthState(firebase.auth);
  const [{activeFilters: activityFilters}, activityFiltersDispatch] = useActivityFilters();

  const [tags] = useSchemaContent('activityCategories');

  const [navItems] = useNavItems('mainMenu');

  const [totalTags, setTotalTags] = useState([]);

  const [{ tagsModalOpened }, dispatch] = useAppState();

  const toggleTagFilter = (tag) => {
    if (activityFilters.some(filter => filter.id === tag.id)) {
      activityFiltersDispatch({ type: 'REMOVE_ACTIVITY_FILTER', payload: tag })
    } else {
      activityFiltersDispatch({ type: 'ADD_ACTIVITY_FILTER', payload: tag })
    }
  };

  const handleSignOut = () => {
    return firebase.auth.signOut();
  };

  useEffect(() => {
    if (tags) {
      let tagsArr = [].concat(Object.values(tags)).sort(sortByOrder);
      setTotalTags(tagsArr);
    }
  }, [tags]);

  return (
    <div className={`side-nav side-nav--col ${isDrawerOpened ? 'is-opened' : ''}`}>
      <div className="side-nav--wrapper">
        <button className="side-nav--button-close" data-action="closeMenu">
          <span></span>
        </button>

        <ul className="side-navigation">
          {navItems ? navItems.items.map((item, i) => {
            if (userLoading) return null;

            if (!!user) {
              return (
                <li className={`side-navigation__item`} key={i}>
                  <NavLink to={item.url} exact activeClassName="side-navigation__item--active">
                    <i className={`icon ${item.cssClass}`}></i>
                    <span> {item.title}</span>
                  </NavLink>
                </li>
              )
            } else {
              if (PRIVATE_ROUTES.indexOf(item.url) !== -1) return null;
              return (
                <li className={`side-navigation__item`} key={i}>
                  <NavLink to={item.url} exact activeClassName="side-navigation__item--active">
                    <i className={`icon ${item.cssClass}`}></i>
                    <span> {item.title}</span>
                  </NavLink>
                </li>
              )
            }
          }) : null}

          { !userLoading && !!user ? (
            <li className={`side-navigation__item side-navigation__item--last`} onClick={() => handleSignOut()}>
              <i className={`icon icon-exit`}/>
              <span>sign out</span>
            </li>
          ) : null}
        </ul>

        <hr />
        {/*{navFiltersEnabled ?*/}
        {/*  <ul className="side-filters side-filters--primary">*/}
        {/*    <p className="side-filters--title">Filter nearby:</p>*/}
        {/*    {totalTags.map((item, idx) => {*/}
        {/*      if (idx > MAX_TAGS_WHEN_COLLAPSED - 1 && totalTags.length > MAX_TAGS_WHEN_COLLAPSED - 1) {*/}
        {/*        if (!tagListExpanded) return null;*/}
        {/*      }*/}

        {/*      return (*/}
        {/*        <li key={idx}>*/}
        {/*          <button className={`side-filters__item bg-blue--${activityFilters.some(filter => filter.id === item.id) ? 'light' : 'lighter'}`}*/}
        {/*            onClick={() => toggleTagFilter(item)}>*/}
        {/*            {item.categoryName}*/}
        {/*          </button>*/}
        {/*        </li>*/}
        {/*      )*/}

        {/*    })}*/}
        {/*    {totalTags.length > MAX_TAGS_WHEN_COLLAPSED - 1 ?*/}
        {/*      (*/}
        {/*        <li>*/}
        {/*          <button className={`side-filters__item bg-blue--light`} onClick={() => dispatch({ type: 'toggleTagsModal', payload: !tagsModalOpened })}>*/}
        {/*            {tagListExpanded ? 'Less' : 'More'}*/}
        {/*          </button>*/}
        {/*        </li>*/}
        {/*      )*/}
        {/*      :*/}
        {/*      null*/}
        {/*    }*/}
        {/*  </ul>*/}
        {/*  : null*/}
        {/*}*/}
        <TagsModal
          isTagsModalOpen={tagsModalOpened}
          tagsList={totalTags}
          onToggleTagFilter={toggleTagFilter}
          startIndex={MAX_TAGS_WHEN_COLLAPSED}
        />
      </div>
    </div>
  )
}

export default compose(
  withRouter,
  withFirebase
)(SideNavigation);
