import React from 'react'

import './TagsModal.scss'
import {useActivityFilters} from "../../contexts/ActivityFiltersContext/useActivityFilters";

const TagsModal = ({
  isTagsModalOpen,
  tagsList,
  onToggleTagFilter,
  startIndex
}) => {

  const [{activeFilters: activityFilters}, activityFiltersDispatch] = useActivityFilters();

  return (
    <div className={'tags-modal ' + (isTagsModalOpen ? 'tags-modal--open' : '')}>
      <div className="tags-modal--wrapper">
        {
          tagsList && tagsList.length > 0 ?
            tagsList.map((item, idx) => {
              if (idx >= startIndex) {
                return (
                  <button key={idx} className={`category-filters__item bg-blue--${activityFilters.some(filter => filter.id === item.id) ? 'light' : 'lighter'}`}
                    onClick={() => onToggleTagFilter(item)}>
                    {item.categoryName}
                  </button>
                )
              } else return null;
            })
            :
            null
        }
      </div>
    </div>
  )
};

export default TagsModal
