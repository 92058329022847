import React, { useState, useEffect, useReducer } from 'react';

import './InterestsList.scss';

const interestsReducer = (state, action) => {
  switch(action.type) {
    case 'TOGGLE_INTEREST':
      let newState = [];

      if (state.filter(item => item.id === action.payload.id).length > 0) {
        newState = state.filter(item => item.id !== action.payload.id)
      } else {
        newState = [...state, action.payload]
      }

      return newState;

    default:
      return state;
  }
};

const InterestsList = ({ editable = true, initialInterests = [], interests, onSelect }) => {

  const [selectedInterests, dispatchInterests] = useReducer(interestsReducer, initialInterests);

  useEffect(() => {
    console.log('selected interests', selectedInterests);
    onSelect(selectedInterests);
  }, [selectedInterests]);

  return (
    <section className="interests-list">
      { interests.map((interest) => (
        <div className={`interests-list--item ${!editable ? 'not-editable' : ''} ${selectedInterests.filter((int) => int.id === interest.id).length > 0 ? 'selected' : ''}`}
             key={interest.id}
             onClick={() => editable ? dispatchInterests({ type: 'TOGGLE_INTEREST', payload: interest }) : null}>
          <div className="checkmark"/>
          <p className="description">{interest.categoryName}</p>
        </div>
      ))}
    </section>
  )
};

export default InterestsList;
