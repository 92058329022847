import { useState, useEffect } from 'react';

import Firebase from '../../firebase';

export default function useSchemaContent(schemaId) {
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();

    const firebase = new Firebase();
    setLoading(true)
    firebase.getSchemaContent(schemaId)
      .then(setContent)
      .then(() => {
        setLoading(false)
      }).catch(setError)

    return function cleanup() {
      abortController.abort();
    }
  }, [schemaId]);

  return [
    content,
    loading,
    error
  ]
}
